import { useEffect } from 'react'
import { notification } from 'antd'
import { useCompanyStore } from 'store/company.store'
import { config } from 'utils/config'
import { useAuthStore } from 'store/auth.store'
import { fetchEventSource } from 'utils/eventsource'
import type { EventSourceMessage } from 'utils/eventsource'

const closed: string[] = []

export function Events() {
  const token = useAuthStore((state) => state.token)

  const [api, contextHolder] = notification.useNotification()
  const company_id = useCompanyStore((state) => state.id) || 0

  useEffect(() => {
    const ctrl = new AbortController()
    const url = config.api.baseURL + 'sse'
    if (!token) return
    fetchEventSource(url, {
      signal: ctrl.signal,
      headers: { Authorization: `Bearer ${token}` },
      body: JSON.stringify({companyId: company_id}),
      onmessage: onMessage,
      onclose: () => {
      },
      // onerror: console.log
    })

    return () => {
      ctrl.abort()
    }
  }, [token])

  const onMessage = (event: EventSourceMessage) => {
    try {
      const data = JSON.parse(event.data.replaceAll('\\', '')) as IMessage
      console.info('onMessage sse', data)

      if (!data?.id || !data?.payload?.message) return
      if (closed.includes(data?.id)) return

      api.info({ message: 'Info', description: data?.payload?.message })
    } catch (error) {
      console.log('error: ' + error)
    }
  }

  return contextHolder
}

interface IMessage {
  id: string
  type: EventType
  payload: {
    message: string
  }
}

enum EventType {
  Connection,
}
