import { notification } from 'antd'
import { api } from './axios'

export function downloadBlob(blob: Blob, filename: string) {
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = filename
  document.body.appendChild(a)
  a.click()
  a.remove()
}

export const downloadFile = async (url: string, data: any) => {
  try {

    const response = await api.post(url, data, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf,text/csv'
      }
    });

    const contentType = response.headers['content-type'];

    // Handle JSON error responses
    if (contentType?.includes('application/json')) {
      const textResponse = await new Response(response.data).text();
      const errorData = JSON.parse(textResponse);
      throw new Error(errorData.error || errorData.message);
    }

    // Handle successful file download
    const filename = response.headers['content-disposition']?.split('filename=')[1]?.replace(/"/g, '')
      || `${data.template || 'report'}-${Date.now()}.${url.includes('pdf') ? 'pdf' : 'csv'}`;

    const blob = new Blob([response.data], { type: contentType });
    const downloadUrl = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();

    // Cleanup
    window.URL.revokeObjectURL(downloadUrl);
    link.remove();
  } catch (error: any) {
    console.error('Download error:', error);

    if (error.response?.data instanceof Blob) {
      const text = await error.response.data.text();
      try {
        const errorData = JSON.parse(text);
        error.message = errorData.error || errorData.message;
      } catch (e) {
        console.error('Error parsing error response:', e);
      }
    }

    notification.error({
      message: 'Download Failed',
      description: error.message || 'Failed to download file',
      duration: 5
    });

    throw error;
  }
};