import React, { useCallback, useState } from 'react'
import { Modal, Flex, Typography, Input } from 'antd';

const { Text } = Typography;

interface ShopifyConnectModalProps {
  visible: boolean;
  onCancel: () => void;
  onConnect: (storeUrl: string) => void;
  loading: boolean;
}

const ShopifyConnectModal: React.FC<ShopifyConnectModalProps> = ({ visible, onCancel, onConnect, loading }) => {
  const [storeUrl, setStoreUrl] = useState<string>('');

  const cleanShopifyUrl = useCallback((url: string): string => {
    // Remove http:// or https:// if present
    let cleanedUrl = url.replace(/^(https?:\/\/)/, '');
    // Remove www. if present
    cleanedUrl = cleanedUrl.replace(/^www\./, '');
    return cleanedUrl;
  }, []);

  const handleConnect = () => {
    // Add .myshopify.com only when connecting
    const finalUrl = storeUrl.endsWith('.myshopify.com')
        ? storeUrl
        : storeUrl + '.myshopify.com';
    onConnect(finalUrl);
    // Reset the input after submission
    setStoreUrl('');
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    // Only clean the URL without adding .myshopify.com
    const cleanedValue = cleanShopifyUrl(inputValue);
    setStoreUrl(cleanedValue);
  };

  return (
      <Modal
          title="Connect to Shopify"
          open={visible}
          onCancel={onCancel}
          onOk={handleConnect}
          okButtonProps={{ disabled: !storeUrl.trim() || loading, loading: loading }}
          cancelButtonProps={{ disabled: loading }}
          closable={!loading}
          maskClosable={!loading}
      >
        <Flex vertical gap={10}>
          <Text>Enter your Shopify store URL:</Text>
          <Input
              placeholder="yourstorename.myshopify.com"
              value={storeUrl}
              onChange={handleInputChange}
              disabled={loading}
          />
        </Flex>
      </Modal>
  );
};

export default ShopifyConnectModal;