import React, { useState } from 'react';
import { Tooltip, Button } from 'antd';
import { PlayCircle as PlayCircleIcon } from 'lucide-react';

interface VideoTooltipProps {
  videoUrl: string;
  buttonText?: string;
  tooltipPlacement?: 'top' | 'left' | 'right' | 'bottom' | 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | 'leftTop' | 'leftBottom' | 'rightTop' | 'rightBottom';
  videoWidth?: number;
  allowHover?: boolean; // Allow the video to play on hover
}

const VideoTooltip: React.FC<VideoTooltipProps> = ({
                                                     videoUrl,
                                                     buttonText = "Watch Tutorial Video",
                                                     tooltipPlacement = "leftTop",
                                                     videoWidth = 720,
                                                     allowHover = false,
                                                   }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleClick = () => {
    window.open(videoUrl, '_blank');
  };

  return (
      <div
          onMouseEnter={() => allowHover && setIsHovered(true)}
          onMouseLeave={() => allowHover && setIsHovered(false)}
          style={{ position: 'relative' }}
      >
        <Tooltip
            placement={tooltipPlacement}
            open={isHovered}
            overlayInnerStyle={{
              padding: 0,
              maxWidth: 'none'
            }}
            overlayStyle={{
              maxWidth: 'none'
            }}
            title={
              <div style={{ width: videoWidth }}>
                <video
                    width="100%"
                    controls
                    autoPlay={isHovered}
                    muted
                    className="rounded-lg"
                >
                  <source src={videoUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            }
        >
          <Button
              type="text"
              onClick={handleClick}
              className="bg-gray-50 rounded-full px-4 py-2 h-auto hover:bg-gray-100"
              style={{ display: 'inline-flex', alignItems: 'center' }}
          >
            <PlayCircleIcon
                className="w-5 h-5"
                style={{ marginRight: 8, flexShrink: 0 }}
            />
            <span
                className="font-medium"
                style={{ lineHeight: 1 }}
            >
            {buttonText}
          </span>
          </Button>
        </Tooltip>
      </div>
  );
};

export default VideoTooltip;