import ReactDOM from 'react-dom/client'

import { ConfigProvider, ThemeConfig } from 'antd'

import { App } from 'app/App'

import { config } from 'utils/config'
import { sentry } from 'utils/sentry'

import 'styles/index.css'
import { initHeap } from './utils/heap'
import flagsmith from 'flagsmith'
import { useEffect, useState } from 'react'
import { FlagsmithProvider } from 'flagsmith/react'

const theme: ThemeConfig = {
  hashed: false,
  token: {
    colorLink: '#0F9648',
    colorPrimary: '#0F9648',
    colorSuccess: '#11C675',
    colorError: '#D13055',
    colorInfo: '#0B73EA',
    borderRadius: 6,
    controlHeight: 40,
    colorBgBase: '#FCFCFC',
  },
  components: {
    Button: {
      defaultShadow: 'none',
      primaryShadow: 'none',
      boxShadow: 'none',
      fontWeight: 600,
    },
    Layout: {
      bodyBg: '#F9F9F8',
      headerBg: 'transparent',
      footerBg: 'transparent',
      siderBg: '#F6F6F6',
      triggerBg: 'transparent',
      triggerColor: '#0F9648',
      headerHeight: 48,
    },
    Menu: {
      itemSelectedBg: 'transparent',
      subMenuItemBg: 'transparent',
      collapsedIconSize: 14,
      iconSize: 14,
      itemHeight: 36,
    },
    Tooltip: {
      controlHeight: 32,
    },
    Select: {
      optionSelectedColor: '#0F9648',
      optionSelectedBg: '#ECF4EF',
    },
    Tabs: {},
    Table: {
      rowSelectedBg: '#ECF4EF',
      rowSelectedHoverBg: '#ECF4EF',
    },
    Steps: {
      descriptionMaxWidth: 260,
    },
  },
}

interface FlagsmithWrapperProps {
  children: React.ReactElement;
}

const defaultFlags = {
  disable_chrome_plugin: {
    enabled: false,
    value: null,
  },
  hide_shopify: {
    enabled: false,
    value: null,
  }
}

function FlagsmithWrapper({ children }: FlagsmithWrapperProps) {
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    flagsmith.init({
      environmentID: process.env.REACT_APP_FLAGSMITH_KEY,
      api: 'https://flagsmith.equilityhq.com/api/v1/',
      defaultFlags,
      onError: (error) => {
        console.warn('Flagsmith error, using default flags:', error)
        setInitialized(true)
      }
    }).catch(error => {
      console.warn('Flagsmith initialization failed:', error)
      setInitialized(true)
    }).then(() => {
      setInitialized(true)
    })
  }, [])

  if (!initialized) {
    return <div />
  }

  return (
    <FlagsmithProvider
      flagsmith={flagsmith}
      options={{
        environmentID: process.env.REACT_APP_FLAGSMITH_KEY,
        defaultFlags,
        cacheFlags: true,
        cacheOptions:{
          ttl: 60 * 1000, // one minute
        },
        api: 'https://flagsmith.equilityhq.com/api/v1/',
      }}
    >
      {children}
    </FlagsmithProvider>
  )
}



if (!config.isLocal) {
  sentry.init(config.sentry.dsn)
  initHeap()
  // Dittofeed.initialize()
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

if (window.self !== window.top) root.render(<div />)

root.render(
  <ConfigProvider theme={theme}>
    <FlagsmithWrapper>
      <App />
    </FlagsmithWrapper>
  </ConfigProvider>,
)
