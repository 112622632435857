import { useEffect, useState } from 'react'
import { useLocation, useMatch, useNavigate } from 'react-router-dom'
import { Button, Divider, Flex, Input, Modal, Result, notification } from 'antd'
import { Tag, Typography, Table } from 'antd'
import type { TableColumnsType } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined, CommentOutlined } from '@ant-design/icons'
import { ShareAltOutlined } from '@ant-design/icons'
import { ColorNumber } from 'components/ColorNumber'
import { FullLoader } from 'components/FullLoader'
import { getMethodLabel } from 'components/SelectMethod'
import { getEntityLabel } from 'components/SelectEntity'
import { Details } from './components/Details'
import AnimatedProgress from 'components/AnimatedProgress'

import { useReview } from 'hooks/useReview'
import { useCompany } from 'hooks/useCompany'
import { useUser } from 'hooks/useUser'
import { useRole } from 'hooks/useRole'
// import { useDefaultRules } from 'hooks/useDefaultRule'
import { useAccounts } from 'hooks/useAccounts'
import { formatDate } from 'utils/dates'
import { getErrorMsg } from 'utils/geterror'
import { isPluginInstalled, runReviewSync } from 'utils/plugin'
import { RoleType } from 'types/user.types'
import { ConnectionType } from 'types/company.types'
import type { IBaseReportRow } from 'types/report.type'
import type { IReview } from 'types/review.types'
// import { downloadFile } from '../../../utils/download'
import { Tooltip } from 'antd'
import { DownOutlined, UpOutlined } from '@ant-design/icons'

const { Text } = Typography

export function ReviewResult() {
  const navigate = useNavigate()

  const { isAllowed } = useRole(RoleType.Member)
  const { state } = useLocation()
  const { company } = useCompany()
  const { user } = useUser()
  const { accounts } = useAccounts()

  // const { data: defaultRules } = useDefaultRules(company?.entity)

  const [changes, setChanges] = useState<IReview>()

  const isPlugin = isPluginInstalled()
  const qbo = (company?.connections || []).find((i) => i.type.id === ConnectionType.QBO)

  const match = useMatch('company/:companyId/review/result/:id')
  const id = match?.params?.id || changes?.id

  const params = { id, companyId: company?.id, ...state }
  const review = useReview(params)

  const data = changes || review.data

  // New state for tracking expanded keys for both tables
  const [balanceSheetExpandedKeys, setBalanceSheetExpandedKeys] = useState<string[]>([])
  const [incomeStatementExpandedKeys, setIncomeStatementExpandedKeys] = useState<string[]>([])

  // Function to get all expandable keys for a specific table
  const getAllExpandedKeys = (data: IBaseReportRow[]): string[] => {
    const keys: string[] = []

    const traverse = (rows: IBaseReportRow[]) => {
      rows.forEach((row) => {
        if (row.type === 'Section') {
          keys.push(row.key)
          if ('rows' in row && (row as any).rows) {
            traverse((row as any).rows)
          }
        }
      })
    }

    traverse(data)
    return keys
  }

  // Function to expand all keys for a table
  const handleExpandAll = (tableType: 'balanceSheet' | 'incomeStatement') => {
    const allKeys = tableType === 'balanceSheet'
      ? getAllExpandedKeys(balanceSheet)
      : getAllExpandedKeys(incomeStatement)

    if (tableType === 'balanceSheet') {
      setBalanceSheetExpandedKeys(allKeys)
    } else {
      setIncomeStatementExpandedKeys(allKeys)
    }
  }

  // Function to collapse all keys for a table
  const handleCollapseAll = (tableType: 'balanceSheet' | 'incomeStatement') => {
    if (tableType === 'balanceSheet') {
      setBalanceSheetExpandedKeys([])
    } else {
      setIncomeStatementExpandedKeys([])
    }
  }

  // Check if all keys are already expanded
  const isFullyExpanded = (data: IBaseReportRow[], expandedKeys: string[]) => {
    const allKeys = getAllExpandedKeys(data)
    return allKeys.length === expandedKeys.length
  }


  const renderExpandCollapseButtons = (
    tableType: 'balanceSheet' | 'incomeStatement',
    data: IBaseReportRow[],
    expandedKeys: string[],
  ) => {
    return (
      <Flex gap={10} style={{ marginBottom: 16 }}>
        <Tooltip title="Use this button to only show the primary parent accounts.">
          <Button
            icon={<UpOutlined />}
            onClick={() => handleCollapseAll(tableType)}
            disabled={expandedKeys.length === 0}
          >
            Collapse All
          </Button>
        </Tooltip>
        <Tooltip title="Use this button to show all parent and child general ledger accounts.">
          <Button
            icon={<DownOutlined />}
            onClick={() => handleExpandAll(tableType)}
            disabled={isFullyExpanded(data, expandedKeys)}
          >
            Expand All
          </Button>
        </Tooltip>
      </Flex>
    )
  }

  useEffect(() => {
    if (!company) return
    if (!id && !!state?.from && !!state?.to) {
      review
        .create(state)
        .then(setChanges)
        .catch((err) => notification.error(getErrorMsg(err)))
    }
  }, [id, state, company])

  if (review?.error) return <ErrorMessage />
  if (!data?.data) return <FullLoader />

  const balanceSheet: IBaseReportRow[] = data?.data.balanceSheet?.report || []
  const incomeStatement: IBaseReportRow[] = data?.data.incomeStatement?.report || []

  const allRecords = [...balanceSheet, ...incomeStatement]
  const failedallRecords = allRecords.filter((record) => !record.meta?.check?.result)
  const percent = ((allRecords.length - failedallRecords.length) / allRecords.length) * 100

  // const generatePdf = async () => {
  //   if (!data?.data || !state.method || !state.from || !state.to || !company) {
  //     notification.error({
  //       message: 'Error',
  //       description: 'Required report parameters or company data is missing',
  //     })
  //     return
  //   }
  //
  //
  //   // Helper function to get description from default rules
  //   const getDefaultRuleDescription = (ruleName: string) => {
  //     const defaultRule = defaultRules?.find(rule => rule.name === ruleName)
  //     return defaultRule?.description || ''
  //   }
  //
  //   // Helper function to collect all rules with their status
  //   const collectRules = (records: IBaseReportRow[]) => {
  //     const rules: Array<{
  //       accountName: string;
  //       name: string;
  //       description: string;
  //       note: string;
  //       result: boolean;
  //       isCustom: boolean;
  //     }> = []
  //
  //     const processRecord = (record: any) => {
  //       if (record.type === 'Data' && record.meta?.check?.rules) {
  //         record.meta.check.rules.forEach((ruleData: any) => {
  //           // Use default rule description for non-custom rules, otherwise use the rule's description
  //           rules.push({
  //             accountName: record.name,
  //             name: ruleData.rule.name,
  //             description: ruleData.rule.description,
  //             note: getDefaultRuleDescription(ruleData.rule.name),
  //             result: ruleData.result,
  //             isCustom: !!ruleData.rule.formula,
  //           })
  //         })
  //       }
  //
  //       // Process children if they exist
  //       if (record.rows) {
  //         record.rows.forEach(processRecord)
  //       }
  //     }
  //
  //     records.forEach(processRecord)
  //     return rules
  //   }
  //
  //
  //   const { balanceSheet, incomeStatement } = data.data
  //   const userName = [state.user?.firstName, state.user?.lastName].filter(Boolean).join(' ')
  //
  //   // Calculate total rules and results
  //   const calculateRuleStats = (records: IBaseReportRow[]) => {
  //     return records.reduce((acc, record) => {
  //       const defaultRules = record.meta?.check?.rules?.filter((r: any) => !r.formula).length || 0
  //       const customRules = record.meta?.check?.rules?.filter((r: any) => !!r.formula).length || 0
  //       const passed = record.meta?.check?.result ? 1 : 0
  //       const failed = record.meta?.check?.result ? 0 : 1
  //
  //       return {
  //         default: acc.default + defaultRules,
  //         custom: acc.custom + customRules,
  //         passed: acc.passed + passed,
  //         failed: acc.failed + failed,
  //       }
  //     }, { default: 0, custom: 0, passed: 0, failed: 0 })
  //   }
  //
  //   const balanceSheetStats = calculateRuleStats(balanceSheet?.report || [])
  //   const incomeStatementStats = calculateRuleStats(incomeStatement?.report || [])
  //
  //   const entityLabel = getEntityLabel(company.entity as any) || 'N/A'
  //   const methodLabel = getMethodLabel(state.method) || 'N/A'
  //
  //   // Collect all rules from both reports
  //   const balanceSheetRules = collectRules(balanceSheet?.report || [])
  //   const incomeStatementRules = collectRules(incomeStatement?.report || [])
  //   const allRules = [...balanceSheetRules, ...incomeStatementRules]
  //
  //   // Separate rules by status and type
  //   const ruleAnalysis = {
  //     failed: {
  //       default: allRules.filter(r => !r.isCustom && !r.result),
  //       custom: allRules.filter(r => r.isCustom && !r.result),
  //     },
  //     passed: {
  //       default: allRules.filter(r => !r.isCustom && r.result),
  //       custom: allRules.filter(r => r.isCustom && r.result),
  //     },
  //   }
  //
  //   const pdfData: FullReportPdfDto = {
  //     template: 'fullReport',
  //     data: {
  //       // Base required fields from GeneratePdfDto
  //       currency: company.settings?.currency || 'USD',
  //       method: state.method,
  //       from: state.from,
  //       to: state.to,
  //       summarizeBy: state.summarizeBy || 'month',
  //
  //       // Company information
  //       company: {
  //         id: company.id,
  //         name: company.name,
  //         entity: entityLabel,
  //         qbo_id: company.qbo_id || '',
  //         alias: company.alias,
  //         settings: company.settings,
  //       },
  //
  //       // Report metadata
  //       reportMeta: {
  //         date: formatDate(state.createdAt || new Date(), 'll'),
  //         dateRange: {
  //           from: state.from,
  //           to: state.to,
  //         },
  //         method: methodLabel,
  //         currency: company.settings?.currency || 'USD',
  //         summarizeBy: state.summarizeBy || 'month',
  //         completedBy: userName,
  //         accuracy: {
  //           passed: +percent.toFixed(2),
  //           failed: +(100 - percent).toFixed(2),
  //         },
  //       },
  //
  //       // Balance sheet data
  //       balanceSheet: {
  //         meta: balanceSheet.meta,
  //         report: balanceSheet.report.map(row => {
  //           if (row.type === 'Data') {
  //             return {
  //               ...row,
  //               meta: {
  //                 ...row.meta,
  //                 rules: row.meta?.check?.rules || [],
  //                 result: row.meta?.check?.result,
  //                 override: row.meta?.override,
  //                 comment: row.meta?.comment,
  //               },
  //             }
  //           }
  //           return row
  //         }),
  //       },
  //
  //       // Income statement data
  //       incomeStatement: {
  //         meta: incomeStatement.meta,
  //         report: incomeStatement.report.map(row => {
  //           if (row.type === 'Data') {
  //             return {
  //               ...row,
  //               meta: {
  //                 ...row.meta,
  //                 rules: row.meta?.check?.rules || [],
  //                 result: row.meta?.check?.result,
  //                 override: row.meta?.override,
  //                 comment: row.meta?.comment,
  //               },
  //             }
  //           }
  //           return row
  //         }),
  //       },
  //
  //       // Analysis summary
  //       analysis: {
  //         totalRules: {
  //           default: balanceSheetStats.default + incomeStatementStats.default,
  //           custom: balanceSheetStats.custom + incomeStatementStats.custom,
  //         },
  //         failedRules: balanceSheetStats.failed + incomeStatementStats.failed,
  //         passedRules: balanceSheetStats.passed + incomeStatementStats.passed,
  //         ruleDetails: {
  //           failed: {
  //             default: ruleAnalysis.failed.default.map(rule => ({
  //               accountName: rule.accountName,
  //               ruleName: rule.name,
  //               description: rule.description,
  //               note: rule.note
  //             })),
  //             custom: ruleAnalysis.failed.custom.map(rule => ({
  //               accountName: rule.accountName,
  //               ruleName: rule.name,
  //               description: rule.description,
  //             })),
  //           },
  //           passed: {
  //             default: ruleAnalysis.passed.default.map(rule => ({
  //               accountName: rule.accountName,
  //               ruleName: rule.name,
  //               description: rule.description,
  //               note: rule.note,
  //             })),
  //             custom: ruleAnalysis.passed.custom.map(rule => ({
  //               accountName: rule.accountName,
  //               ruleName: rule.name,
  //               description: rule.description
  //             })),
  //           },
  //         },
  //       },
  //     },
  //   }
  //
  //   try {
  //     await downloadFile('/file-generation/pdf', pdfData)
  //   } catch (err) {
  //     // Error handling is managed by downloadFile utility
  //     console.error('PDF generation error:', err)
  //   }
  // }

  const onComment = (record: IBaseReportRow) => {
    Modal.info({
      title: 'Comment',
      content: (
        <Flex vertical gap={10}>
          <Input.TextArea
            disabled={!isAllowed}
            allowClear
            rows={4}
            defaultValue={record.meta?.comment?.text}
            onChange={(e) => {
              const comment = e.target.value
                ? {
                  text: e.target.value,
                  user: [user?.firstName, user?.lastName].join(' '),
                  date: new Date().toISOString(),
                }
                : null
              const meta = { ...record.meta, comment }
              const newRecord = { ...record, meta }
              onRecordChange(newRecord)
            }}
          />
          {record.meta?.comment && (
            <Text>
              Note by {record.meta?.comment?.user} on {formatDate(record.meta?.comment?.date)}
            </Text>
          )}
        </Flex>
      ),
      width: 600,
      closable: true,
    })
  }

  const onRecordChange = (record: IBaseReportRow) => {
    const prev = changes || review.data
    if (!prev) return

    const reportType = record.key.split('-')[0]
    const rIndex = reportType === 'balancesheet' ? 'balanceSheet' : 'incomeStatement'
    const arr = prev.data[rIndex].report

    const isResult = (r: IBaseReportRow) => r.meta?.check?.result || r.meta?.override?.result

    function deepMap(v: IBaseReportRow): IBaseReportRow {
      if (v.key === record.key) return record
      if (v.type === 'Section' && v.rows) {
        const rows = v.rows?.map(deepMap)
        const check = { ...v.meta.check, result: v.meta.override?.result || rows.every(isResult) }
        const meta = { ...v.meta, check }
        return { ...v, rows, meta }
      }
      return v
    }

    const newReport = [...arr].map(deepMap)
    const newData = { ...prev.data[rIndex], report: newReport }
    setChanges({ ...prev, data: { ...prev.data, [rIndex]: newData } })
  }

  const onDetails = (record: any) => {
    let title = record.meta?.check?.result ? 'Passed' : 'Failed'
    let action: 'success' | 'warning' = record.meta?.check?.result ? 'success' : 'warning'

    // Check for rules (both default and custom) in the record
    const hasDefaultRules = checkForDefaultRules(record)
    const hasCustomRules = checkForCustomRules(record)

    // If no rules exist, update the title and action
    if (!hasDefaultRules && !hasCustomRules) {
      title = 'No Rules'
      action = 'warning' // Set to warning because no rules exist
    }

    if (record.meta?.override?.result) {
      title = 'Overriden'
      action = 'success'
    }

    const account = accounts.find((v) => v.name === record.name)
    const parrent =
      record.key.search('-summary') > 0
        ? treeFind(allRecords, record.key.replace('-summary', ''))
        : treeFind(allRecords, record.key.split('-').slice(0, -1).join('-'))

    const modal = Modal[action]({
      title: title,
      content: (
        <Details
          update={(_) => modal.update(_)}
          record={record}
          onChange={(_, isOveridden) => {
            onRecordChange(_)
            if (isOveridden) onComment(_)
          }}
          onCancel={() => modal.destroy()}
          from={state.from}
          to={state.to}
          method={state.method}
          account={account}
          parrent={parrent}
        />
      ),
      width: 800,
      closable: true,
      footer: null,
    })
  }

  const onSave = () => {
    if (!isAllowed) return notification.error({ message: 'Error', description: 'You are not allowed to save' })
    if (!changes) return alert('No changes')
    review
      .update({ ...changes, rating: percent })
      .then(() => notification.success({ message: 'Success', description: 'Review saved' }))
      .catch((err) => notification.error(getErrorMsg(err)))
  }

  const onRun = () => {
    if (!company) return
    if (isPlugin) {
      const connectionInfo = qbo?.connectionInfo || '{}'
      runReviewSync({
        from: state.from,
        to: state.to,
        companyId: company.id,
        realmId: connectionInfo.realm_id,
        method: state.method,
      })
    } else {
      const pathname = `/company/${company.id}/review`
      navigate(pathname)
    }
  }

  columns[1].render = (_, record) => {
    const value = record.meta?.check?.rules?.filter((v: any) => !v.formula).length || 0
    const children = flatten(record.meta?.check?.children || []).flatMap((v: any) => v.rules)
    const childrenLength = children.filter((v: any) => !v.formula).length

    if (record.type === 'Section') {
      const isExpanded = record.key.startsWith('balancesheet')
        ? balanceSheetExpandedKeys.includes(record.key)
        : incomeStatementExpandedKeys.includes(record.key)
      return isExpanded ? value || '' : (value + childrenLength) || ''
    }
    if (record.type === 'Summary') {
      return value || ''
    }
    return value || ''
  }

  columns[2].render = (_, record) => {
    const value = record.meta?.check?.rules?.filter((v: any) => !!v.formula).length || 0
    const children = flatten(record.meta?.check?.children || []).flatMap((v: any) => v.rules)
    const childrenLength = children.filter((v: any) => !!v.formula).length

    if (record.type === 'Section') {
      const isExpanded = record.key.startsWith('balancesheet')
        ? balanceSheetExpandedKeys.includes(record.key)
        : incomeStatementExpandedKeys.includes(record.key)
      return isExpanded ? value || '' : (value + childrenLength) || ''
    }
    if (record.type === 'Summary') {
      return value || ''
    }
    return value || ''
  }

  columns[3].render = (value: any, record) => {
    if (record.type === 'Section') {
      const isExpanded = record.key.startsWith('balancesheet')
        ? balanceSheetExpandedKeys.includes(record.key)
        : incomeStatementExpandedKeys.includes(record.key)
      if (isExpanded) return value && <ColorNumber amount={value} />
      const summary: any = record.rows?.find((v: any) => v.type === 'Summary')
      return (
        <b>
          <ColorNumber amount={summary?.value} />
        </b>
      )
    }
    if (record.type === 'Summary')
      return (
        <b>
          <ColorNumber amount={value} />
        </b>
      )
    return <ColorNumber amount={value} />
  }

  columns[5].render = (_, record) => {
    return (
      <Flex gap={10}>
        <Button
          type="text"
          size="small"
          style={detailsStyle}
          onClick={() => onComment(record)}
          icon={<CommentOutlined />}
          danger={record.meta?.comment}
        />
        <Button size="small" style={detailsStyle} onClick={() => onDetails(record)}>
          Details
        </Button>
      </Flex>
    )
  }

  const roudedPercent = +percent.toFixed(2)
  const userName = [state.user?.firstName, state.user?.lastName].filter(Boolean).join(' ')

  return (
    <Flex vertical>
      <Divider orientation="left">Equility Company</Divider>
      <Flex justify="space-between" align="center">
        <Flex gap={40}>
          <AnimatedProgress percent={roudedPercent} strokeWidth={10} />
          <Flex vertical gap={10}>
            <Flex gap={10}>
              <Text strong>Report date:</Text>
              <Text>{formatDate(state.createdAt || new Date(), 'll')}</Text>
            </Flex>
            <Flex gap={10}>
              <Text strong>Date range:</Text>
              <Text>
                {formatDate(state.from, 'll')} - {formatDate(state.to, 'll')}
              </Text>
            </Flex>
            <Flex gap={10}>
              <Text strong>Accounting method:</Text>
              <Text>{getMethodLabel(state.method)}</Text>
            </Flex>
            <Flex gap={10}>
              <Text strong>Business entity:</Text>
              <Text>{getEntityLabel(company?.entity as any)}</Text>
            </Flex>
            {userName && (
              <Flex gap={10}>
                <Text strong>Completed By:</Text>
                <Text>{userName || ''}</Text>
              </Flex>
            )}
          </Flex>
        </Flex>
        <Flex gap={10} vertical>
          <Button
            type="primary"
            onClick={onSave}
            disabled={data.finished || !changes || !isAllowed}
            loading={review.isLoading}
          >
            Save
          </Button>
          <Button onClick={onRun} disabled={data.finished || !isAllowed} loading={review.isLoading}>
            Rerun
          </Button>
          {/* <Button disabled>Download PDF</Button> */}
          <Button disabled icon={<ShareAltOutlined />}>
            Share
          </Button>
          {/*<Button*/}
          {/*  icon={<DownloadOutlined />}*/}
          {/*  onClick={generatePdf}*/}
          {/*  loading={review.isLoading}*/}
          {/*>*/}
          {/*  PDF*/}
          {/*</Button>*/}
        </Flex>
      </Flex>
      <Divider orientation="left">Balance Sheet</Divider>
      {renderExpandCollapseButtons('balanceSheet', balanceSheet, balanceSheetExpandedKeys)}
      <Table
        columns={columns}
        dataSource={balanceSheet}
        pagination={false}
        loading={review.isLoading}
        scroll={{y:100000}}
        rowClassName={(record: any) => record.type || ''}
        expandable={{
          expandedRowKeys: balanceSheetExpandedKeys,
          onExpand: (expanded, record) => {
            const key = record.key
            setBalanceSheetExpandedKeys(prev =>
              expanded
                ? [...prev, key]
                : prev.filter(k => k !== key),
            )
          },
          childrenColumnName: 'rows',
          // expandIcon: () => null,
        }}
      />
      <Divider orientation="left">Income Statement</Divider>
      {renderExpandCollapseButtons('incomeStatement', incomeStatement, incomeStatementExpandedKeys)}
      <Table
        columns={columns}
        dataSource={incomeStatement}
        pagination={false}
        loading={review.isLoading}
        scroll={{y:100000}}
        rowClassName={(record: any) => record.type || ''}
        expandable={{
          expandedRowKeys: incomeStatementExpandedKeys,
          onExpand: (expanded, record) => {
            const key = record.key
            setIncomeStatementExpandedKeys(prev =>
              expanded
                ? [...prev, key]
                : prev.filter(k => k !== key),
            )
          },
          childrenColumnName: 'rows',
          // expandIcon: () => null,
        }}
      />
    </Flex>
  )
}

// Helper function to check if a record or its children have default rules
const checkForDefaultRules = (record: IBaseReportRow): boolean => {
  if (record.type === 'Data') {
    return record.meta?.check?.rules?.some((r: any) => !r.formula) ?? false
  }

  if (record.rows) {
    return record.rows.some((row) => checkForDefaultRules(row))
  }

  return false
}

// Helper function to check if a record or its children have custom rules
const checkForCustomRules = (record: IBaseReportRow): boolean => {
  if (record.type === 'Data') {
    return record.meta?.check?.rules?.some((r: any) => !!r.formula) ?? false
  }

  if (record.rows) {
    return record.rows.some((row) => checkForCustomRules(row))
  }

  return false
}

const flatten = (data: any) => data.flatMap(({ children, ...item }: any) => [item, ...flatten(children)])

const columns: TableColumnsType<IBaseReportRow> = [
  {
    title: 'Account',
    dataIndex: 'name',
    render: (value, record) => {
      if (record.type === 'Summary') return <b>{value}</b>
      return value
    },
  },
  {
    title: 'Default rules',
    dataIndex: 'value',
    width: 180,
    render: (_, record) => {
      return record.meta?.check?.rules?.filter((r: any) => !r.formula).length || ''
    },
  },
  {
    title: 'Custom rules',
    dataIndex: 'value',
    width: 180,
    render: (_, record) => {
      return record.meta?.check?.rules?.filter((r: any) => !!r.formula).length || ''
    },
  },
  {
    title: 'Total',
    dataIndex: 'value',
    width: 180,
  },
  {
    title: 'Result',
    dataIndex: 'result',
    width: 120,
    render: (_, record) => {
      const hasDefaultRules = checkForDefaultRules(record)
      const hasCustomRules = checkForCustomRules(record)

      if (!hasDefaultRules && !hasCustomRules) {
        return <NoRulesTag />
      }
      if (!record.meta?.check) return '-'
      return record.meta?.check?.result ? <PassTag /> : <FailTag />
    },
  },
  {
    title: '',
    dataIndex: 'id',
    width: 100,
  },
]

const PassTag = () => (
  <Tag icon={<CheckCircleOutlined />} color="green">
    Pass
  </Tag>
)
const FailTag = () => (
  <Tag icon={<CloseCircleOutlined />} color="error">
    Fail
  </Tag>
)

const NoRulesTag = () => (
  <Tag icon={<InfoCircleOutlined />} color="orange">
    No Rules
  </Tag>
)

const detailsStyle: React.CSSProperties = {
  marginTop: -5,
  marginBottom: -5,
}

function treeFind(arr: IBaseReportRow[] = [], key: string): IBaseReportRow | undefined {
  if (!arr.length) return undefined
  return arr?.reduce((result: IBaseReportRow | undefined, n) => {
    if (result) return result
    if (n.key === key) return n
    if (n.type === 'Section') return treeFind(n.rows, key)
    return undefined
  }, undefined)
}

const ErrorMessage = () => {
  const navigate = useNavigate()

  return (
    <Result
      status={500}
      title="Server Error"
      subTitle="Something went wrong. Please try again later or contact support."
      extra={
        <Button type="primary" onClick={() => navigate(-1)}>
          Back
        </Button>
      }
    />
  )
}
