import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Tabs } from 'antd'

import { Page } from 'components/Page'
import VideoTooltip from 'components/VideoTooltip'
import { useRole } from 'hooks/useRole'
import { RoleType } from 'types/user.types'

const baseItems = [
  {
    label: 'Run',
    key: '',
  },
  {
    label: 'History',
    key: 'history',
  },
  {
    label: 'Result',
    key: 'result',
  },
]

export function ReviewPage() {
  const navigate = useNavigate()
  const location = useLocation()
  const { isAllowed } = useRole(RoleType.Member)

  const path = location.pathname.split('/review/')?.[1] || ''
  const activeKey = path.split('/')[0]
  const isNestedRoute = path.includes('rules/')

  // Filter tabs based on conditions
  const tabs = baseItems.filter((v) => v.key !== 'result' || activeKey === 'result')

  // Remove first two tabs if not allowed
  if (!isAllowed) {
    tabs.splice(0, 2)
  }

  // For invalid routes, default to first tab
  // This is done synchronously to maintain plugin compatibility
  if (!isNestedRoute && !tabs.find((v) => v.key === activeKey)) {
    navigate(tabs[0].key)
  }

  const handleTabChange = (key: string) => {
    navigate(key)
  }

  const tabBarExtraContent = {
    right: (
      <VideoTooltip
        videoUrl="https://assets.equilityhq.com/documentation-videos/Financial-Review-Walkthrough.mp4"
        videoWidth={720}
      />
    )
  }

  return (
    <Page title="Financial Review">
      <Tabs
        items={tabs}
        onChange={handleTabChange}
        activeKey={isNestedRoute ? '' : activeKey}
        tabBarExtraContent={tabBarExtraContent}
      />
      <Outlet />
    </Page>
  )
}