import { Button, Flex, Typography } from 'antd'

import { TxList } from 'components/TxList'

import { useRole } from 'hooks/useRole'
import { RoleType } from 'types/user.types'
import { toCurrency } from 'utils/numbers'
import type { IBaseReportRow } from 'types/report.type'
import type { IAccount } from 'types/account.types'

const { Text } = Typography

interface Props {
  record: IBaseReportRow
  from: string
  to: string
  method?: string
  account?: IAccount
  parrent?: IBaseReportRow
  onChange: (changes: IBaseReportRow, isOveridden?: boolean) => void
  onCancel: () => void
  update: (configUpdate: any) => void
}

export function Details(props: Props) {
  const { record, onCancel, onChange, update } = props
  const { from, to, method, account, parrent } = props

  const { isAllowed } = useRole(RoleType.Member)

  const getAllChildren = (record: any) => {
    const allChecks: any[] = []
    
    const traverse = (node: any) => {
      if (node.meta?.check?.rules?.length) {
        // Include the override result if it exists
        allChecks.push(node.meta?.override?.result ? 
          { ...node.meta.check, result: true } : 
          node.meta.check
        )
      }
      if (node.rows?.length) {
        node.rows.forEach(traverse)
      }
    }

    if (record.rows) {
      record.rows.forEach(traverse)
    }

    return allChecks
  }


  const children = getAllChildren(record)
  const arr = children.filter((v: any) => v.rules?.length)
  const all = arr.length
  const passed = arr.filter((v: any) => v.result).length
  const failed = all - passed

  const rules = record.meta?.check?.rules || []
  const isChildrenPass = children.every((v: any) => v.result)
  const isOveridden = !!record.meta?.override?.result
  const disableOverride = !rules.length || !isChildrenPass

  const isReconcile = record.meta?.check?.rules?.some(
    (v: any) => v.rule.name === 'Checking & credit card reconciliation',
  )

  const treeIds = getTreeIds([record])
  const ids = treeIds.length ? treeIds : parrent ? getTreeIds([parrent]) : []

  const onOverride = () => {
    const check = { ...record.meta.check, result: true }
    const override = { result: true, prev: record.meta.check }
    onChange({ ...record, meta: { ...record.meta, check, override } }, true)
    onCancel()
  }

  const onRevertOverride = () => {
    const check = { ...record.meta.override.prev }
    onChange({ ...record, meta: { ...record.meta, check, override: null } }, false)
    onCancel()
  }

  const showTransactions = () => {
    const txProps = { accounts: ids, from, to, method }
    update({
      title: 'Transactions',
      icon: null,
      content: (
        <Flex vertical gap={10}>
          <TxList {...txProps} />
          <Flex justify="flex-end" gap={10}>
            <Button type="primary" onClick={onCancel}>
              Ok
            </Button>
          </Flex>
        </Flex>
      ),
    })
  }

  const showUnclearedTransactions = () => {
    const txProps = { accounts: ids, from, to, method }
    update({
      title: 'Uncleared Transactions',
      icon: null,
      content: (
        <Flex vertical gap={10}>
          <TxList key={ids.toString()} {...txProps} uncleared />
          <Flex justify="flex-end" gap={10}>
            <Button type="primary" onClick={onCancel}>
              Ok
            </Button>
          </Flex>
        </Flex>
      ),
    })
  }

  return (
    <Flex vertical gap={10} style={{ marginTop: 10 }}>
      <Text>
        Account: <b>{record.name}</b>
      </Text>
      {!!account && (
        <Text>
          Account type:{' '}
          <b>
            {account.classification} ({account.sybtype})
          </b>
        </Text>
      )}
      <Text>
        Date range: <b>{from}</b> - <b>{to}</b>
      </Text>
      {rules.map((r: any, i: number) => (
        <Flex gap={10} vertical key={'r' + i}>
          <Flex vertical key={r.rule.id}>
            <Flex justify="space-between">
              <Flex gap={5}>
                <Text>
                  Rule Name: <b>{r.rule.name}</b>
                </Text>
                <Text strong type={isOveridden ? 'success' : record.meta?.check?.result ? 'success' : 'danger'}>
            {isOveridden ? 'Passed' : record.meta?.check?.result ? 'Passed' : 'Failed'}
          </Text>
              </Flex>
            </Flex>
            <Text type="secondary">
              Description: <span dangerouslySetInnerHTML={{ __html: r.rule.description }}></span>
            </Text>
            {}
            {!!r.compareStr && (
              <>
                <Text type="secondary">
                  <b>Rule: </b>to pass{' '}
                  <u>
                    {record.name} {r.rule.target}
                  </u>{' '}
                  needs to be <u>{getTextFromCondition(r.rule.condition)}</u>:{' '}
                  <u>{r.formula || toCurrency(r.ruleValue)}</u>
                </Text>
                <Text type="secondary">
                  <b>Formula output: </b>
                  {toCurrency(r.ruleValue)}
                </Text>
                <Text type="secondary">
                  <b>
                    <u>
                      {record.name} {r.rule.target}
                    </u>
                    :{' '}
                  </b>
                  {toCurrency(r.value === 'NaN' ? 0 : r.value)}
                </Text>
                <Text type="secondary">
                  <b>Result: </b>{' '}
                  <u>
                    {record.name} {r.rule.target} is {r.result ? '' : 'not'} {getTextFromCondition(r.rule.condition)}
                  </u>{' '}
                  the formula output
                </Text>
              </>
            )}
          </Flex>
        </Flex>
      ))}
      {!!failed && (
        <Text>
          <Text strong type="danger">
            Failed:{' '}
          </Text>
          Your child accounts Passed {passed} out of {all} rules. Please review.
        </Text>
      )}
      {!!passed && !failed && (
        <Text>
          <Text strong type="success">
            Passed:{' '}
          </Text>
          Your child accounts Passed {passed} out of {all} rules, great job!
        </Text>
      )}
      <Flex justify="flex-end" gap={10} style={{ marginTop: 20 }}>
        {!record.meta.check.result && !isOveridden && (
          <Button danger onClick={onOverride} disabled={disableOverride || !isAllowed}>
            Override
          </Button>
        )}
        {!!isOveridden && (
          <Button danger onClick={onRevertOverride} disabled={!isAllowed}>
            Revert override
          </Button>
        )}
        {isReconcile && (
          <Button onClick={() => showUnclearedTransactions()} disabled={!ids.length}>
            View Uncleared Transactions
          </Button>
        )}
        <Button onClick={() => showTransactions()} disabled={!ids.length}>
          View All Transactions
        </Button>
        <Button type="primary" onClick={onCancel}>
          Ok
        </Button>
      </Flex>
    </Flex>
  )
}

// function treeToFlat(tree: any[]) {
//   return tree.reduce((acc, node) => {
//     const { children, ...rest } = node
//     acc.push({ ...rest, isParent: !!children?.length })
//     if (children) acc.push(...treeToFlat(children))
//     return acc
//   }, [])
// }

function getTreeIds(data: any[]) {
  const r: string[] = []

  function process(data: any[]) {
    data.forEach((item) => {
      r.push(item.id)
      if (item.rows?.length > 0) process(item.rows)
    })
  }

  process(data)
  return r.filter((v) => v)
}

const getTextFromCondition = (target: string) => {
  switch (target) {
    case '==':
      return 'equal to'
    case '>':
      return 'more than'
    case '<':
      return 'less than'
    case '>=':
      return 'more than or equal to'
    case '<=':
      return 'less than or equal to'

    default:
      return ''
  }
}
