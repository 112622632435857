import React, { useState, useEffect } from 'react';
import {List, Switch, Select, Flex, Tooltip} from 'antd';
import { IConnectionData } from '../types/connection.types';
import { SourceDataSelectorProps, SourceItem } from '../types/source.types'

const SourceDataSelector = ({
                              sources,
                              onSourceChange
                            }: SourceDataSelectorProps<IConnectionData>): JSX.Element => {
  const [sourceStates, setSourceStates] = useState<Record<string, boolean>>(() =>
    sources.reduce(
      (acc, source) => ({
        ...acc,
        [source.id]: source.active !== undefined ? source.active : true,
      }),
      {}
    )
  );

  const [selectedConnections, setSelectedConnections] = useState<Record<string, number>>(() =>
    sources.reduce(
      (acc, source) => ({
        ...acc,
        [source.id]: source.connections?.[0]?.keyId || 0,
      }),
      {}
    )
  );

  useEffect(() => {
    sources.forEach(source => {
      const initialState = source.active !== undefined ? source.active : true;
      const initialConnection = source.connections?.[0];

      onSourceChange({
        sourceId: source.id,
        enabled: initialState,
        connectionId: initialConnection?.type?.id || null,
        connection: initialConnection || null
      });
    });
  }, []);

  const handleToggle = (source: SourceItem<IConnectionData>, checked: boolean) => {
    setSourceStates(prev => ({
      ...prev,
      [source.id]: checked,
    }));

    if (!checked) {
      onSourceChange({
        sourceId: source.id,
        enabled: false,
        connectionId: null,
        connection: null
      });
    } else {
      const firstConnection = source.connections?.[0];
      if (firstConnection) {
        setSelectedConnections(prev => ({
          ...prev,
          [source.id]: firstConnection.keyId,
        }));
        onSourceChange({
          sourceId: source.id,
          enabled: true,
          connectionId: firstConnection.type.id,
          connection: firstConnection
        });
      } else {
        onSourceChange({
          sourceId: source.id,
          enabled: true,
          connectionId: null,
          connection: null
        });
      }
    }
  };

  const handleConnectionSelect = (source: SourceItem<IConnectionData>, connectionKeyId: number) => {
    setSelectedConnections(prev => ({
      ...prev,
      [source.id]: connectionKeyId,
    }));

    const selectedConnection = source.connections?.find(conn => conn.keyId === connectionKeyId);
    if (selectedConnection) {
      onSourceChange({
        sourceId: source.id,
        enabled: true,
        connectionId: selectedConnection.type.id,
        connection: selectedConnection
      });
    }
  };



  const renderSourceItem = (source: SourceItem<IConnectionData>) => {
    const hasConnections = source.requiresSelection &&
      Array.isArray(source.connections) &&
      source.connections.length > 0;
    const selectedConnectionId = selectedConnections[source.id];


    const toggleContent = (
        <Switch
            checked={sourceStates[source.id]}
            disabled={source.disabled}
            onChange={(checked: boolean) => handleToggle(source, checked)}
            style={{ width: 44 }}
        />
    )

    return (
      <List.Item key={source.id}>
        <Flex vertical gap={10} className="w-full p-4">
          <List.Item.Meta
              title={source.title}
              description={source.description}
              className="mb-2"
          />
          <Flex vertical gap={8}>
            {source.tooltip ? (
                <Tooltip
                    title={source.tooltip.title}
                    placement={source.tooltip.placement || 'right'}
                >
                  {toggleContent}
                </Tooltip>
            ) : toggleContent}
            {hasConnections && sourceStates[source.id] && (
              <Select
                className="w-full"
                placeholder={`Select ${source.title} account`}
                value={selectedConnectionId || undefined}
                onChange={(value) => handleConnectionSelect(source, Number(value))}
              >
                {source.connections?.map((connection) => (
                  <Select.Option
                    key={connection.keyId}
                    value={connection.keyId}
                    disabled={connection.status !== 1}
                  >
                    {source.getConnectionLabel?.(connection) ||
                      connection.name ||
                      connection.connectionInfo?.shop_domain ||
                      `Store ${connection.keyId}`}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Flex>
        </Flex>
      </List.Item>
    );
  };

  return (
    <List
      grid={{ gutter: 16, column: 4 }}
      dataSource={sources}
      renderItem={renderSourceItem}
    />
  );
};

export default SourceDataSelector;