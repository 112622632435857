import { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Divider,
  Flex,
  notification,
  Typography,
  Table,
  Modal,
  TableColumnsType,
  Form,
  Row, Switch,
} from 'antd'
import { SelectEntity } from 'components/SelectEntity'
import { SelectMethod } from 'components/SelectMethod'
import { AlertPlugin } from 'components/AlertPlugin'

import { useRole } from 'hooks/useRole'
import { useCompany } from 'hooks/useCompany'
import { formatDate, parseDateRage } from 'utils/dates'
import { isPluginInstalled, runReviewSync } from 'utils/plugin'
import { ConnectionType, IRule } from 'types/company.types'
import { RoleType } from 'types/user.types'
import { useFlags } from 'flagsmith/react'
import type { IDefaultRule } from 'types/default-rules.types'
import { useDefaultRules } from '../../../hooks/useDefaultRule'
import { useRules } from '../../../hooks/useRules'
import SourceDataSelector from '../../../components/SourceDataSelector'
import { SourceChangeEvent, SourceItem } from '../../../types/source.types'
import { IConnectionData, Integrations } from '../../../types/connection.types'

const { RangePicker } = DatePicker
const { Text } = Typography
const dateRange = parseDateRage()


interface SearchParams {
  daterange: string;
  sync: string;
}

interface SourceState {
  enabled: boolean;
  connectionId?: ConnectionType;
  connection?: IConnectionData;
}


export function ReviewRun() {
  const navigate = useNavigate()
  const { rules = [], remove, isLoading } = useRules()

  /* Used for filtering on any particular record*/
  const customRules = useRules()
  /* handling the Rule toggle*/
  const initialCustomRuleData: IRule[] = rules
  const [, setRuleData] = useState<IRule[]>([])

  const [sourceStates, setSourceStates] = useState<Record<string, SourceState>>({})
  const flags = useFlags(['disable_chrome_plugin', 'hide_shopify'])

  const [searchParams] = useSearchParams()
  const [isInitialized, setIsInitialized] = useState(false)

  const { company } = useCompany()
  const { isAllowed } = useRole(RoleType.Member)

  const [form] = Form.useForm()

  const entity = company?.entity
  const method = company?.settings?.accountingMethod || 'Cash'

  const qbo = (company?.connections || []).find((i) => i.type.id === ConnectionType.QBO)
  const isConnected = qbo?.status === 1

  const shopify = company?.connections?.filter((i) => i.type.id === ConnectionType.Shopify)
  const sourceData: SourceItem[] = [
    {
      id: 'qbo',
      title: 'QuickBooks Online',
      description: 'Accounting data',
      disabled: true,
      requiresSelection: false,
      connections: qbo ? [qbo] : [],
      active: true, // QBO is always enabled by default
    },
    {
      id: 'shopify',
      title: 'Shopify',
      description: 'E-commerce data',
      disabled: flags.hide_shopify.enabled || (!shopify || shopify.length === 0),
      requiresSelection: true,
      connections: shopify,
      getConnectionLabel: (connection) =>
        connection.connectionInfo?.store?.store_name || connection.connectionInfo?.shop_name || `Store ${connection.id}`,
      active: shopify && shopify.length > 0 , // Shopify starts enabled by default
      tooltip: (!shopify || shopify.length === 0) ? {
        title: (
            <span>Please configure Shopify in <Link to={`/company/${company?.id}/settings/integrations`}>Company Settings</Link>.</span>
        ),
        placement: 'right',
      }: undefined}
  ]

  const isPlugin = isPluginInstalled()

  // Extract search params processing
  const search: SearchParams = useMemo(
    () => Object.fromEntries(searchParams) as any,
    [searchParams],
  )

  useEffect(() => {
    if (!company || !search.daterange || !search.sync || isInitialized) return

    const daterange = search.daterange.split('--')
    const state = {
      from: daterange[0],
      to: daterange[1],
      method: company?.settings?.accountingMethod || 'Cash',
      entity: company?.entity,
    }

    setIsInitialized(true)
    localStorage.removeItem('qbosync')
    navigate(`/company/${company.id}/review/result`, { state })
    setRuleData(initialCustomRuleData)

  }, [company, search, initialCustomRuleData, isInitialized])

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [selectedRecord, setSelectedRecord] = useState<any | null>(null)

  const handleRowClick = useCallback((record: IDefaultRule) => {
    setSelectedRecord(record)
    setIsModalVisible(true)
  }, [])

  const closeModal = () => {
    setIsModalVisible(false)
    setSelectedRecord(null)
  }

  const handleSourceChange = ({ sourceId, enabled, connectionId, connection }: SourceChangeEvent<IConnectionData>) => {
    setSourceStates(prev => ({
      ...prev,
      [sourceId]: {
        enabled,
        connectionId: connectionId ?? undefined,
        connection: connection ?? undefined,
      },
    }))
  }

  const runReview = (state: any) => {
    const path = `/company/${company?.id}/review/result`
    navigate(path, { state })
  }

  const onSubmit = (values: any) => {
    if (!company) return
    if (!isConnected && !flags?.disable_chrome_plugin?.enabled)
      return notification.error({
        message: 'Error',
        description: 'QuickBooks Online is not connected',
      })

    // Build an array of objects with name and integrationKeyId from enabled non-QBO connections
    const integrations: Integrations[] = Object.entries(sourceStates)
      .filter(([sourceId, src]) =>
        src.enabled &&
        src.connection?.keyId &&
        sourceId !== 'qbo',  // Exclude QBO connections
      )
      .map(([sourceId, src]): Integrations => ({
        name: sourceId,
        integrationKeyId: src.connection!.keyId,
      }))


    const state = {
      from: formatDate(values.daterange[0], 'YYYY-MM-DD'),
      to: formatDate(values.daterange[1], 'YYYY-MM-DD'),
      method: values.method,
      entity: values.entity || undefined,
      integrations: integrations,
    }

    if (!isPlugin) {
      if (!flags?.disable_chrome_plugin?.enabled) {
        notification.warning({ message: 'Warning', description: 'Equility Plugin is not installed' })
      }
      runReview(state)
    } else {
      const connectionInfo = qbo?.connectionInfo || '{}'
      runReviewSync({
        from: state.from,
        to: state.to,
        companyId: company.id,
        realmId: connectionInfo.realm_id,
        method: state.method,
        // integrations: state.integrations
      })
    }
  }

  // Use the new hook to fetch default rules
  const { data: defaultRules = [], isLoading: isLoadingDefaultRules } = useDefaultRules(entity)

  const onDelete = useCallback(
    (id: number) => {
      remove(id)
    },
    [remove],
  )


  const handleSuccess = () => {
    notification.success({ message: 'Success', description: 'Rule updated' })
  }
  const handleSwitchToggle = (id: number) => (checked: boolean) => {
    if (id) {
      const rule = id ? customRules.rules?.find((r) => r.id === id) : undefined
      if (rule) {
        const updatedRule = { ...rule, active: checked }
        customRules.update(updatedRule).then(handleSuccess)
      }
    }

    setRuleData((prevRuleData) =>
      prevRuleData.map((rule) =>
        rule.id === id ? { ...rule, active: checked } : rule,
      ),
    )
  }

  const renderActions = useCallback(
    (_: any, rule: IRule) => (
      <Flex gap={10}>
        <Link to={`rules/${rule.id}`}>
          <Button size="small" type="text">
            Edit
          </Button>
        </Link>
        <Button size="small" type="text" danger onClick={() => onDelete(rule.id)}>
          Delete
        </Button>
      </Flex>
    ),
    [onDelete],
  )

  const renderSwitch = useCallback((_: any, rule: IRule) => (

    <Switch
      checked={rule.active} // Set initial checked state from 'active'
      onChange={handleSwitchToggle(rule.id)} // Handle toggle changes locally
    />
  ), [handleSwitchToggle])

  const columns: TableColumnsType<IRule> = useMemo(
    () => [
      {
        title: 'Rule',
        dataIndex: 'name',
        width: 300,
        ellipsis: true,
      },
      {
        title: 'Description',
        dataIndex: 'description',
        ellipsis: true,
      },
      {
        title: 'Source Account',
        dataIndex: 'account',
        width: 300,
        ellipsis: true,
        render: (account) => account?.label,
      },
      {
        title: 'Enabled/Disabled',
        dataIndex: 'active',
        ellipsis: true,
        render: renderSwitch,
      },
      {
        title: 'Action',
        dataIndex: 'id',
        width: 150,
        render: renderActions,
      },
    ],
    [renderSwitch, renderActions])


  const columns2: TableColumnsType<IDefaultRule> = [
    {
      title: 'Rule',
      dataIndex: 'name',
      width: 300,
      ellipsis: true,
    },
  ]


  const onRow = useCallback(
    (record: IDefaultRule) => ({
      onClick: () => handleRowClick(record),
    }),
    [handleRowClick],
  )

  if (!company?.id) return null
  if (!isAllowed) return null

  return (
    <Flex vertical>
      {!isConnected && !flags?.disable_chrome_plugin?.enabled && (
        <Alert
          message="Quickbooks Integration"
          description={
            <Text>
              <a href={`/company/${company?.id}/settings/accounting`}>Connect your accounting platform</a> to use the features
            </Text>
          }
          type="warning"
          showIcon
        />
      )}
      <AlertPlugin />
      <Form layout="vertical" form={form} onFinish={onSubmit}>
        <Row gutter={24}>
          <Col span={16}>
            <Divider orientation="left">Review Parameters</Divider>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item label="Date Range" name="daterange" initialValue={dateRange}>
                  <RangePicker style={inputStyle} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Accounting Method" name="method" initialValue={method}>
                  <SelectMethod />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Divider orientation="left">Business info</Divider>
            <Form.Item
              label="Legal Entity Type"
              name="entity"
              initialValue={entity}
              tooltip="If you'd like to change your legal entity type, please navigate to Settings > Business Details and select the desired option from the 'Legal Entity Type' dropdown."
            >
              <SelectEntity disabled />
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation="left">Source data</Divider>
        <SourceDataSelector
          sources={sourceData}
          onSourceChange={handleSourceChange}
        />
        <Link to="../settings/integrations" relative="path">
          <Button>Setup source data</Button>
        </Link>

        <Flex vertical gap={10}>
          <Flex vertical>
            <Divider orientation="left">Custom Rules</Divider>
            <Table
              loading={isLoading}
              dataSource={rules || []}
              columns={columns}
              rowKey="id"
              pagination={false}
            />
          </Flex>
          <Flex justify="flex-start" style={{ marginTop: 20 }}>
            <Link to="./rules/add">
              <Button type="primary" style={{ width: 200 }}>
                Add Rule
              </Button>
            </Link>
          </Flex>
          <Flex vertical>
            <Divider orientation="left">Default Review Rules</Divider>
            <Flex vertical gap={10}>
              <Text>
                The default rules are applied to all companies by default based on their business
                entity type. Custom rules can be added to make them more specific to your
                company.
              </Text>
              <Table
                loading={isLoadingDefaultRules}
                dataSource={defaultRules}
                columns={columns2}
                rowKey="id"
                pagination={false}
                onRow={onRow}
              />
            </Flex>
          </Flex>
        </Flex>

        <Divider />
        <Flex justify="space-between">
          <Button type="primary" style={runStyle} htmlType="submit">
            Run
          </Button>
        </Flex>
      </Form>

      <Modal
        title={selectedRecord?.name}
        open={isModalVisible}
        footer={null}
        onCancel={closeModal}
        width={600}
      >
        {selectedRecord && (
          <Flex vertical gap={16}>
            <Typography.Title level={5}>Description</Typography.Title>
            <div
              dangerouslySetInnerHTML={{
                __html: selectedRecord.description,
              }}
              className="rule-description"
            />
            <style>{`
              .rule-description {
                font-size: 14px;
                line-height: 1.5;
                color: rgba(0, 0, 0, 0.85);
              }
              .rule-description br {
                margin-bottom: 8px;
              }
              .rule-description span {
                display: block;
                margin-bottom: 16px;
              }
              .rule-description ul, 
              .rule-description ol {
                margin: 8px 0;
                padding-left: 24px;
              }
              .rule-description li {
                margin-bottom: 8px;
              }
              .rule-description p {
                margin-bottom: 16px;
              }
            `}</style>
          </Flex>
        )}
      </Modal>
    </Flex>
  )
}

const runStyle: React.CSSProperties = {
  width: 200,
}

const inputStyle: React.CSSProperties = {
  width: '100%',
}